
export default {
    name: 'EnvelopeComp',
    props: {
        name: {
            type: String,
            default: "[NAME]",
        },
    },
}
