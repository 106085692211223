import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.text, (letter, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        style: _normalizeStyle({color: _ctx.spaced_colors[index]})
      }, _toDisplayString(letter), 5))
    }), 128))
  ]))
}