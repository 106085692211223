
export default {
    name: 'RevealComp',
    props: {
        name: {
            type: String,
            default: "[NAME]",
        },
        show_button: {
            type: Boolean,
            default: true,
        },
    },
}
