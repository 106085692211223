
export default {
    name: 'ButtonComp',
    props: {
        color: {
            type: String,
            default: "red",
        },
        enabled: {
            type: Boolean,
            default: true
        }
    },
}
