
import { defineComponent } from 'vue';
import { useUserStore } from "@/store";
import { mapState } from "pinia";
import EnvelopeComp from "@/components/EnvelopeComp.vue";
import ClockComp from "@/components/ClockComp.vue";
import ButtonOrderComp from "@/components/ButtonOrderComp.vue";
import ButtonGateComp from "@/components/ButtonGateComp.vue";
import RevealComp from "@/components/RevealComp.vue";

export default defineComponent({
    name: 'GameView',
    data() {
        return {
            error: "",
            opened: false
        }

    },
    components: {
        EnvelopeComp,
        ClockComp,
        ButtonOrderComp,
        ButtonGateComp,
        RevealComp
    },
    computed: {
        // ready_tasks() {
        //   return useUserStore().projects.map((x) => x.ready_subtasks).reduce((x, y) => x + y, 0)
        // },
        // total_tasks() {
        //   return useUserStore().projects.map((x) => x.total_subtasks - x.done_subtasks).reduce((x, y) => x + y, 0)
        // },
        ...mapState(useUserStore, ["userName", "isLoggedIn", "gameState"])
    },
    mounted: function () {
        const code = this.$route.params.code || "";
        const router = this.$router;
        useUserStore().fetchUser(code).then((x) => {
            if (x == false) router.replace("/404");
        });
        console.log("Glass Youngin");
    },
    methods: {
        openLetter() {
            useUserStore().getWebsocket()
            setTimeout(this.openLetter, 1500);
            this.opened = true
        },
        async requestCompass() {
            const compass_handler = this.compass_handler;
            console.log("requestCompass");
            try {
                if (DeviceOrientationEvent && typeof((DeviceOrientationEvent as any).requestPermission) === "function") {
                    const permissionState = await (DeviceOrientationEvent as any).requestPermission();
                    if (permissionState === "granted") {
                        // Permission granted
                        console.log("Permission granted");
                        window.addEventListener("deviceorientation", compass_handler, true);
                    } else {
                        // Permission denied
                        console.log("Permission denied");
                        console.log("falling back to deviceorientationabsolute");
                        window.addEventListener("deviceorientationabsolute", compass_handler, true);
                    }
                }
                else {
                    window.addEventListener("deviceorientation", compass_handler, true);
                    window.addEventListener("deviceorientationabsolute", compass_handler, true);
                }
            }
            catch (e){
                console.error(e);
                console.log("falling back to deviceorientationabsolute");
                window.addEventListener("deviceorientationabsolute", compass_handler, true);
            }
        },
        compass_handler(e:any){
            const new_heading = Number(e.webkitCompassHeading || Math.abs(e.alpha - 360));
            console.log("Compass handler!", e);
            useUserStore().setCompassFunctional();
            if (useUserStore().compassFunctional){
                useUserStore().updateCompassHeading(new_heading);
            }
        }
    }
});
