
import { defineComponent } from 'vue';
import { useUserStore } from "@/store";
import { mapState } from "pinia";
import ButtonComp from "@/components/ButtonComp.vue"
import RainbowTextComp from '@/components/RainbowTextComp.vue';

export default defineComponent({
    name: 'ButtonOrderedComp',
    data() {
        return {
        }
    },
    components: {
        ButtonComp,
        RainbowTextComp
    },
    computed: {
        buttonEnabled() {
            return useUserStore()._buttonOrder.indexOf(useUserStore().userId) == -1
        },
        ...mapState(useUserStore, ["buttonOrder", "buttonOrderAttempts", "userColor", "colorSequence"])
    },
    mounted: function() {
        useUserStore().releaseGateButton();
    },
    methods: {
        // TODO ...mapActions?
        pressGateButton() {
            useUserStore().pressGateButton();
        },
    }
});
