import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import GameView from '../views/GameView.vue';
import NotFoundView from '../views/404View.vue'

import 'vue-router'
import { useUserStore } from '@/store';
declare module 'vue-router' {
  interface RouteMeta {
    // if not defined, we assume auth is required
    authRequired?: boolean
  }
}

const routes = [
  {
    path: '/',
    name: 'index',
    component: HomeView,
    meta: {
      authRequired: false,
    }
  },
  {
    path: '/g/:code',
    name: 'the game',
    component: GameView,
    meta: {
        authRequired: false,
      }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/error',
    name: '400',
    component: () => import(/* webpackChunkName: "error" */ '../views/400View.vue'),
    meta: {
      authRequired: false,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFoundView,
    meta: {
      authRequired: false,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (useUserStore().isLoggedIn) {
    return true;
  }
  if (to.meta.authRequired == undefined) return true;
  if (to.meta.authRequired == true) return { name: '400' };
})

export default router
