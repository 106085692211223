import {defineStore} from 'pinia';
import axios from "axios";
import _ from "lodash";
import {type GameState, PlayerInitialData, WSMessage} from "../../shared/api_types";

export const useUserStore = defineStore("user", {
    state: () => {

        return {
            _loggedIn: (window as any).logged_in || false,
            _userDataCurrent:false,
            _userName: "",
            _userID: "",
            _ws:null as WebSocket | null | Promise<WebSocket|null>,
            _debouncedFunc: null as any,
            _userColor: "red",
            _buttonOrderMax: 0,
            _headings: {} as {[key: string]: (number|null)},
            _buttonOrder: [] as string[],
            _orderAttempts: 0,
            _colorSequence: [] as string[],
            _gameState: ("envelope" as GameState),
            _players: [] as string[],
            _compassUpdates: 0,
            _heading: 90, // TODO: remove once we support proper headings
        }
    },
    getters: {
        isLoggedIn: (state) => state._loggedIn,
        userName: (state) => state._userName,
        userId: (state) => state._userID,
        userColor: (state) =>state._userColor,
        colorSequence: (state) => state._colorSequence,
        gameState: (state) => state._gameState,
        buttonGateCount: (state) => state._buttonOrder.length,
        buttonGateMax: (state) => Math.max(state._buttonOrderMax, state._players.length),
        buttonOrder: (state) => state._buttonOrder,
        buttonOrderMax: (state) => state._buttonOrderMax,
        buttonOrderAttempts: (state) => state._orderAttempts,
        headings: (state) => state._headings,
        heading: (state) => state._heading,
        compassFunctional: (state) => state._compassUpdates > 5,
    },
    actions:{
        async getWebsocket() {
            if (this._ws != null && this._ws instanceof Promise) return await this._ws;
            if (this._ws) return this._ws;
            const self = this;
            this._ws = new Promise<WebSocket|null>((resolve, reject) => {
                let hostname = window.location.host;
                if (hostname.endsWith(":8788")) hostname = hostname.slice(0,-5)+":8787";
                else if (hostname.endsWith(":8787")) hostname = hostname.slice(0,-5)+":8787";
                else hostname = "glassyoungin-gameroom.matthewcdev.workers.dev";
                const wss = document.location.protocol === "http:" ? "ws://" : "wss://";
                const ws = new WebSocket(wss + hostname + "/api/room/glassonion/websocket");
                let resolved = false;
                ws.addEventListener("open", event => {
                    self._ws = ws;
                    ws.send(JSON.stringify({name:self._userID}));
                    resolved = true; 
                    resolve(ws);
                });
                ws.addEventListener("close", event => {
                    console.log("WebSocket closed, reconnecting:", event.code, event.reason);
                    self._ws = null;
                });
                ws.addEventListener("error", event => {
                    resolved = true;
                    if (!resolved) resolve(null);
                    self._ws = null;
                });
                ws.addEventListener("message", this.getWebsocketMessage);
            });
            return await this._ws;
        },
        async getWebsocketMessage(event: MessageEvent) {
            console.log("Got message:", event.data);
            const message = JSON.parse(event.data) as WSMessage;
            if ("buttons" in message) {
                const players = this._players;
                while (players.length > 0) players.pop();
                while (this._buttonOrder.length > 0) this._buttonOrder.pop();
                for (const button_index in message.buttons) {
                    // TODO: sort this out
                    const [userID, timestamp] = message.buttons[button_index];
                    if (timestamp != null) this._buttonOrder.push(userID);
                    players.push(userID);
                    if (this._headings[userID] == undefined) this._headings[userID] = null;
                }
            }
            else if ("game_state" in message) {
                // TODO: check if this is a valid state
                if (message.game_state != "ordered" && message.game_state != "clock" && message.game_state != "url_taken" && message.game_state != "reveal" && message.game_state != "envelope") {
                    console.error("Invalid game state: ", message.game_state);
                    return;
                }
                this._gameState = message.game_state as GameState;
            }
            else if ("order_fail" in message) {
                this._orderAttempts += 1;
            }
            else if ("headings" in message) {
                for (const [userID, heading] of message.headings) {
                    this._headings[userID] = heading;
                }
            }
            else {
                console.error("Unhandled message", message);
            }
        },
        async fetchUser(code:string|string[]) {
            if (this._userDataCurrent) return;
            try{
                const data = await axios.get("/api/player/"+code);
                const player = (data.data as PlayerInitialData);
                this._userName = player.name;
                this._userID = player.id;
                this._loggedIn = true;
                this._userDataCurrent = true;
                this._gameState = player.state;
                this._userColor = player.color;
                this._buttonOrderMax = player.num_groups;
                const sequence = this._colorSequence;
                while (sequence.length > 0) sequence.pop();
                player.sequence.forEach((x)=>sequence.push(x));
                return true;
            }
            catch {
                console.error("Failed to get user's information");
                return false;
            }
        },
        async updateHeading() {
            if (this.gameState == 'clock') {
                const heading = this._heading;
                const ws = await this.getWebsocket();
                ws.send(JSON.stringify({heading}));
            }
        },
        async setCompassFunctional() {
            if (this._compassUpdates < 15) this._compassUpdates += 1;
        },
        async updateCompassHeading(heading: number) {
            if (Math.abs(heading - this._heading) > 1) {
                this._heading = heading;
                if (this._debouncedFunc == null) {
                    // we debounce this so we don't send too many messages
                    this._debouncedFunc = _.throttle(this.updateHeading, 300);
                }
                this._debouncedFunc()
            }
        },
        // Buttons APIS
        // TODO: consider making this handle both buttons
        async pressGateButton() {
            // websocket.emit("press", this._userID);
            // Add ourselves to the button order
            if (this._buttonOrder.indexOf(this._userID) == -1) this._buttonOrder.push(this._userID);
            (await this.getWebsocket()).send(JSON.stringify("press"));
        },
        async releaseGateButton() {
            // websocket.emit("release", this._userID);
            // remove ourselves from the button order
            const order_index = this._buttonOrder.indexOf(this._userID);
            if (order_index != -1) {
                this._buttonOrder.splice(order_index,1);
            }
            (await this.getWebsocket()).send(JSON.stringify("release"));
        },
        async logOut() {
            this._loggedIn = false;
            this._userDataCurrent = false;
        }
    },
})