
import { defineComponent } from 'vue';
// import { PropType } from "vue"
import { useUserStore } from "@/store";
import { mapState, mapActions } from "pinia";

function getNewPosition(old: number, current: number) {
    
    const adj_old = ((old - 180) > current) ? (old - 360) : ((old+180) < current) ? (old+360) : old;
    // console.log(`getNewPosition old ${old} adj ${adj_old} current ${current}`, old, adj_old);
    const diff = current - adj_old;
    if (Math.abs(diff) < 0.5) return Number(current);
    return Number(old + (diff / 30));
}

export default defineComponent({
    name: 'rainbow-text-comp',
    props: {
        text: {
            type: String,
            default: "[Message]",
        },
    },
    data () {
        return {
            display_headings: {} as {[key: string]: (number|null)},
            ticking: true,
            display_hand: 90,
        }
    },

    computed: {
        hands() {
            // First we need to get everyone who isn't us
            const userId = useUserStore().userId;
            const headings = this.display_headings; //useUserStore().headings;

            const users = Object.keys(headings).filter((x)=>x!=userId);
            if (users.length == 0) {
                return [];
            }
            return users.map((x)=>(headings[x] || 0));
        },
        ...mapState(useUserStore, ["heading", "headings", "compassFunctional"])
    },
    methods: {
        updateHeadings() {
            const headings = useUserStore().headings;
            const new_hand = getNewPosition(this.display_hand, useUserStore().heading);
            // console.log("Updating hand", useUserStore().heading, this.display_hand, new_hand)
            this.display_hand = new_hand;
            if (this.ticking) requestAnimationFrame(this.updateHeadings);
            if (headings == null) {
                return;
            }
            for (const userName of Object.keys(headings)) {
                const heading = headings[userName];
                if (heading == null) {
                    continue;
                }
                let current_heading = this.display_headings[userName];
                if (current_heading == undefined || current_heading == null) {
                    this.display_headings[userName] = heading;
                    continue;
                }
                this.display_headings[userName] = getNewPosition(current_heading, heading);
            }
        },
        ...mapActions(useUserStore, ["updateCompassHeading"])
    },
    mounted() {
        requestAnimationFrame(this.updateHeadings);
    },
    unmounted() {
        this.ticking = false;
    }
})
