
import { defineComponent } from 'vue';
import { PropType } from "vue"

export default defineComponent({
    name: 'rainbow-text-comp',
    props: {
        text: {
            type: String,
            default: "[Message]",
        },
        colors: {
            type: Array as PropType<string[]>,
            default: () => ["red", "green", "blue"]
        },
    },
    computed: {
        spaced_colors: function() {
            let colors: string[] = [];
            let color_index = 0;
            const text = this.text;
            for (let i = 0; i < text.length; i++) {
                if (text[i] == " ") {
                    colors.push("transparent");
                    continue;
                }
                if (color_index >= (this.colors.length*2)) {
                    colors.push("inherit");
                }
                else colors.push(this.colors[color_index % this.colors.length]);
                color_index += 1;
            }
            return colors;
        },
    }
})
