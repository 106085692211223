
import { defineComponent } from 'vue';
import { useUserStore } from "@/store";
import { mapState } from "pinia";
import ButtonComp from "@/components/ButtonComp.vue"

export default defineComponent({
    name: 'ButtonGateComp',
    data() {
        return {
        }
    },
    components: {
        ButtonComp
    },
    computed: {
        ...mapState(useUserStore, ["buttonGateCount","buttonGateMax", "buttonOrder"])
    },
    mounted: function() {
        useUserStore().releaseGateButton();
    },
    methods: {
        // TODO ...mapActions?
        pressGateButton() {
            useUserStore().pressGateButton()
        },
        releaseGateButton() {
            useUserStore().releaseGateButton()
        }
    }
});
